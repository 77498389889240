import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Nicht gefunden" />
    <h2>Seite nicht gefunden</h2>
    <p>Die Seite die du suchst konnte nicht gefunden werden.</p>
    <p>
      <Link to="/">Zurück zur Startseite</Link>
    </p>
  </Layout>
)

export default NotFoundPage
